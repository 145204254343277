import {isArray, forEach} from 'lodash';
import {Question, Section} from '../../functions/src/shared';
import {db} from '@/firebase';

function replacer(item: any) {
  item = item.replace(/["“”]/g, '&quot;')
    .replace(/['‘’]/g, '&#39;')
    .replace(/[–—]/g, '-')
    .replace('&nbsp;', ' ')
    .replace(' ', ' ')
    .replace(/[^\x20-\x7E]/g, '');
  return item;
}

function getQuestion(id: string = '', collection: string) {
  return db.collection(collection).doc(id).get()
    .then((doc: any) => {
      return doc.data();
    });
}

export async function prepExport(data: Question[], collection: string = '') {
  const ids = data.map((item) => item.objectID);
  const items: any = [];
  for (const id of ids) {
    await getQuestion(id, collection).then((question: any) => items.push(question));
  }
  let questions: string = '<ol style="margin:0 0 0 25px;">';
  let answers: string = '<ol style="margin:0 0 0 25px;">';
  forEach(items, (doc: Question) => {
    let answered = false;
    questions += `<li>${replacer(doc.query)}`;
    if (Array.isArray(doc.answers)) {
      questions += '<ol type="A" style="margin:0 0 0 25px;">'; // opening answer list
      for (const [i, answer] of doc.answers.entries()) {
        if (i === doc.answers.length - 1) {
          questions += `<li>${replacer(answer.text).replace('</p>', '<br>&nbsp;</p>')}</li>`; // answer item on question
        } else {
          questions += `<li>${replacer(answer.text)}</li>`; // answer item on question
        }
        if (answer.correct && !doc.feedback) {
          answered = true;
          answers += `<li>${replacer(answer.feedback)}</li>`;
        }
      }
      questions += '</ol>'; // closing answer list
    }
    questions += '</li>'; // closing question item
    if (doc.feedback && !answered) {
      answers += `<li>${replacer(doc.feedback).replace('</p><p>', '<br>').replace('</p>', '<br>&nbsp;</p>')}</li>`;
    }
  });
  questions += '</ol>';
  answers += '</ol>';
  return {questions, answers};
}

export async function prepExportTrueFalse(data: Question[]) {
  const ids = data.map((item) => item.objectID);
  const items: any = [];
  for (const id of ids) {
    await getQuestion(id, 'TrueFalse').then((question: any) => items.push(question));
  }
  let questions: string = '<ol style="margin:0 0 0 25px;">';
  let answers: string = '<ol style="margin:0 0 0 25px;">';
  forEach(items, (doc: Question) => {
    questions += `<li>${replacer(doc.query).replace('</p><p>', '<br>').replace('</p>', '<br>&nbsp;</p>')}</li>`;
    answers += `<li>${replacer(doc.feedback)}</li>`;
  });
  questions += '</ol>';
  answers += '</ol>';
  return {questions, answers};
}

export async function prepExportFeature(data: Question[]) {
  const ids = data.map((item) => item.objectID);
  const items: any = [];
  for (const id of ids) {
    await getQuestion(id, 'LearningFeature').then((question: any) => items.push(question));
  }
  let questions: string = '';
  forEach(items, (doc: Question) => {
    questions += `<strong>${doc.id}:</strong>`;
    questions += `${replacer(doc.query)}`;
    questions += '<ol type="A" style="margin: 0 0 0 25px;">'; // opening answer list
    // @ts-ignore
    for (const answer of doc.answers) {
      questions += `<li>${replacer(answer.text)} ${replacer(answer.feedback)}</li>`; // answer item on question
    }
    questions += '</ol>'; // closing answer list
    questions += '&nbsp;</li>'; // closing question item
  });
  return {questions};
}

export async function prepExportSections(data: Section[]) {

  const questionArray = [];
  const answerArray = [];
  let questionCount = 1;

  for (const s of data) {

    const ids = s.questions.map((item) => item.objectID);

    const items: any = [];

    for (const id of ids) {
      await getQuestion(id, 'LessonQA').then((question: any) => items.push(question));
    }

    let questions: string = '';
    let answers: string = '';

    questions += `<ol style="margin:0 0 0 25px;">`;
    answers += `<ol style="margin:0 0 0 25px;">`;

    forEach(items, (doc: Question) => {
      let answered = false;
      questions += `<li value="${questionCount}">${replacer(doc.query)}`;
      if (isArray(doc.answers)) {
        questions += '<ol type="A" style="margin:0 0 0 25px;">'; // opening answer list
        for (const answer of doc.answers) {
          questions += `<li>${replacer(answer.text)}</li>`; // answer item on question
          if (answer.correct && !doc.feedback) {
            answered = true;
            answers += `<li value="${replacer(questionCount)}">${replacer(answer.feedback)}</li>`;
          }
        }
        questions += '</ol>'; // closing answer list
      }
      questions += '</li>'; // closing question item
      if (doc.feedback && !answered) {
        answers += `<li value="${questionCount}">${replacer(doc.feedback)}</li>`;
      }
      questionCount++;
    });

    questions += '</ol>';
    answers += '</ol>';

    questionArray.push({
      name: s.name,
      html: questions,
    });
    answerArray.push({
      name: s.name,
      html: answers,
    });
  }

  return {questions: questionArray, answers: answerArray};
}







































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {shuffle as _shuffle, chain, zipObject, sortBy} from 'lodash';
import createReport from 'docx-templates';
import moment from 'moment-timezone';
import JSZip from 'jszip';

import {db, storage} from '@/firebase';
import {exportHelper} from '@/utils/search';
import request from '@/utils/request';
import {prepExport, prepExportFeature, prepExportSections, prepExportTrueFalse} from '@/utils/export';

@Component
export default class Export extends Vue {

    @Prop({default: ''}) collection!: string;
    @Prop({default: false}) section!: boolean;
    @Prop({default: false}) all!: boolean;

    loading = true;
    exporting = false;
    initial = true;
    active: any = {
        rank: {},
        series: {},
        procedures: {},
        tags: {},
    };
    collapse = [0, 1];

    name = '';
    facets = [];

    questions: any = [];
    sections: any = [];

    toggleFacet(facet: string, value: string) {
        this.initial = false;
        if (this.active[facet][value]) {
            delete this.active[facet][value];
        } else {
            this.active[facet][value] = true;
        }
        exportHelper.toggleFacetRefinement(facet, value).search();
    }

    shuffle() {
        if (this.section) {
            for (const item of this.sections) {
                if (item.questions) {
                    item.questions = _shuffle(item.questions);
                }
            }
        } else {
            this.questions = _shuffle(this.questions);
        }
    }

    created() {
        exportHelper.on('result', (result: any) => {
            this.facets = result.disjunctiveFacets;
            this.loading = false;
            this.questions = result.hits;
            if (this.collection === 'LessonQA') {
                this.sections = chain(result.hits).groupBy('procedures').toPairs()
                    .map((pair) => zipObject(['name', 'questions'], pair)).value();
                this.sections = sortBy(this.sections, ['name']);
            } else if (this.collection === 'TargetedReview' || this.collection === 'TrueFalse') {
                this.questions = sortBy(this.questions, ['series', 'procedures']);
            }
        });

        exportHelper.clearRefinements().addFacetRefinement('collection', this.collection).search();
    }

    async generate() {
        this.exporting = true;

        let reference = 'templates/questions.docx';
        const data: any = {};

        if (this.collection === 'LessonQA') {
            const prep = await prepExportSections(this.sections);
            data.questions = {sections: prep.questions, headlines: true};
            data.answers = {sections: prep.answers, headlines: false};
            reference = 'templates/sections.docx';
        } else if (this.collection === 'LearningFeature') {
            const prep = await prepExportFeature(this.questions);
            data.questions = {html: prep.questions};
        } else if (this.collection === 'TrueFalse') {
            const prep = await prepExportTrueFalse(this.questions);
            data.questions = {html: prep.questions};
            data.answers = {html: prep.answers};
        } else {
            const prep = await prepExport(this.questions, this.collection);
            data.questions = {html: prep.questions};
            data.answers = {html: prep.answers};
        }

        storage.ref().child(reference).getDownloadURL()
            .then(async (url) => {
                const generatedFiles: any = {};
                return request.get(url, {responseType: 'blob'})
                    .then(async (template) => {
                        generatedFiles.questions = await createReport({
                            template,
                            data: data.questions,
                        });
                        if (data.answers) {
                            generatedFiles.answers = await createReport({
                                template,
                                data: data.answers,
                            });
                        }
                        return generatedFiles;
                    });
            }) // generate documents
            .then((exported: any) => {
                const zip = new JSZip();
                zip.file('Questions.docx', exported.questions);
                if (exported.answers) {
                    zip.file('Answers.docx', exported.answers);
                }
                return zip.generateAsync({type: 'uint8array'})
                    .then((zipData) => zipData);
            }) // generate zip
            .then(async (zip: any) => {
                const date = moment().tz('America/New_York').format('YYYYMMDD-HH-mm-ss');
                await storage.ref().child(`Exports/${this.collection}/${this.collection}-${date}.zip`).put(zip);
                const metadata: any = {
                    collection: this.collection,
                    filename: `${date}`,
                    storage: `Exports/${this.collection}/${this.collection}-${date}.zip`,
                };
                if (Object.keys(this.active.rank).length > 0) {
                    metadata.rank = Object.entries(this.active.rank).map(([key, value]) => key);
                }
                if (Object.keys(this.active.series).length > 0) {
                    metadata.series = Object.entries(this.active.series).map(([key, value]) => key);
                }
                if (Object.keys(this.active.procedures).length > 0) {
                    metadata.procedures = Object.entries(this.active.procedures).map(([key, value]) => key);
                }
                if (Object.keys(this.active.tags).length > 0) {
                    metadata.series = Object.entries(this.active.tags).map(([key, value]) => key);
                }
                return metadata;
            }) // save zip
            .then(async (metadata) => {
                await db.collection('Exports').doc().set({
                    metadata,
                    collection: metadata.collection,
                    filename: metadata.filename,
                    date: new Date(),
                });
                return storage.ref().child(metadata.storage).getDownloadURL()
                    .then((url) => {
                        return url;
                    });
            }) // save export item
            .then((url) => {
                this.$notify({
                    dangerouslyUseHTMLString: true,
                    duration: 20000,
                    title: 'Successful Export',
                    message: `<a href="${url}">Download</a> the exported package or view previous <a href="/exports">exports</a>`,
                    type: 'success',
                });
            }) // notification
            .catch((error) => {
                this.$notify({
                    dangerouslyUseHTMLString: true,
                    duration: 5000,
                    title: 'Error',
                    message: `Something went wrong, please try again`,
                    type: 'error',
                });
                console.error('url', error);
            })
            .then(() => this.exporting = false);
    }

}

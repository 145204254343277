import axios from 'axios';
import {Message} from 'element-ui';
import {auth} from '@/firebase';

// create axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_MOCK_API,
  timeout: 60000,
});

// request interceptor
service.interceptors.request.use(
  async (config) => {
    if (auth.currentUser) {
      // @ts-ignore
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        config.headers.Authorization = token; // make each request include custom token header
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// response interceptor
service.interceptors.response.use(
  (response: any) => {
    return Promise.resolve(response.data);
  },
  (error: any) => {
    Message.error(error.message);
    return Promise.reject(error);
  },
);

export default service;
